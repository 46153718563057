import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import {FiCheck , FiChevronUp} from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Collision Repair Services' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">COLLISION REPAIR SERVICES</h2>
                                    <p>We service all brands of automobiles.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="COLLISION REPAIR SERVICES"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>The Green Light Collision Center – Auto Body Repair Shop is specialized in performing high quality collision repair of vehicles of all types. We do our best to make the repair of your vehicle as smooth as possible in a timely manner.</p>
                                                    <p>The Green Light Collision Center uses the strongest and the most secure materials and parts available. We know that everything we use while repairing and restoring your vehicle will hold up for many years. Our customers will be satisfied with our professional and detailed auto collision repair.</p>
                                                    <p>Getting hit by another vehicle can be perhaps one of the most frightening and stressful things one can go through. However, there is always a way out. The professional and certified technicians of the Green Light Collision Center are here to make your experience less stressful.
                                                        The experienced collision repair specialists of the Green Light Collision Center will make your vehicle look like a brand-new one. They will we review the damages of the collision and provide you with an accurate and exact written repair estimate.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Why Work With Us?</h2>
                                                        <p>Benefits of Green Light Collision Center:</p>
                                                    </div>
                                                    <div className="mt--30">
                                                        <ul className="list-style--1">
                                                            <li><FiCheck />Over 40 years of experience,</li>
                                                            <li><FiCheck />Certified Auto Body Repair Technicians,</li>
                                                            <li><FiCheck />State of Art Repair,</li>
                                                            <li><FiCheck />Contemproary Car Collision Repair Equipment,</li>
                                                            <li><FiCheck />Excellent Customer Service,</li>
                                                            <li><FiCheck />Budget-Friendly Car Repairs,</li>
                                                            <li><FiCheck />Free Estimates,</li>
                                                            <li><FiCheck />Rental Assitance,</li>
                                                            <li><FiCheck />Accindent Assistance,</li>
                                                            <li><FiCheck />24/7 Towing Service,</li>
                                                            <li><FiCheck />Wide range of services, including auto body shop, collision repair, paintless dent repair, auto paint and much more.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/about/benefits.jpg" alt="Benefits of Green Light Collision Center"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;