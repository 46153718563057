import React, { Component } from "react";
import {FaTwitter , FaInstagram , FaFacebookF , FaYelp} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaYelp /> , link: 'https://www.yelp.com/biz/green-light-collision-center-sun-valley-2'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/greenlightcollision/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        <a className="rn-button-style--2" href="/contact-green-light-collision-center">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Link</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/">Home</a></li>
                                                    <li><a href="/collision-repair-services">Services</a></li>
                                                    <li><a href="/about-green-light-collision-center">About</a></li>
                                                    <li><a href="/contact-green-light-collision-center">Let's Talk</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:claims@greenlightcollision.com">claims@greenlightcollision.com</a></li>
                                                    <li><a href="tel:8189623110">(818)962-3110</a></li>
                                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                    <li><a target="_blank" href="https://www.google.com/maps/place/Green+Light+Collision+Center/@34.2395394,-118.3717129,17z/data=!3m1!4b1!4m5!3m4!1s0x80c295da649d0109:0x7bb57ae27bde9110!8m2!3d34.2395394!4d-118.3695242">10923 Randall St. Unit E Sun Valley, CA 91352</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2022 Green Light Collision C. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;