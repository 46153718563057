import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Audi"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Bentley"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="BMW"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Chevrolet"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Ferrari"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="Ford"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="Honda"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.png" alt="Lamborghini"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="Land Rover"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-010.png" alt="Lexus"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-011.png" alt="Nissan"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-012.png" alt="Toyota"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-013.png" alt="Volkswagen"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-014.png" alt="Volvo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-015.png" alt="Mercedes-Benz"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;