import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} - Green Light Collision Center</title>
                    <meta name="description" content="Green Light Collision Center – Auto Body Shop is the leading auto body repair and paint shop located in Los Angeles, CA. Our services includes auto body and paint work, paint less dent repair, car body work and more. We are the best auto body shop near your location in Los Angeles, CA!" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
