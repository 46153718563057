import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import {FiCheck , FiChevronUp} from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Auto Body Shop Los Angeles | Car Body Repair' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About Us'}   />
                {/* End Breadcrump Area */}



                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/about/about-green.jpg" alt="COLLISION REPAIR SERVICES"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">About Us</h2>
                                                    </div>
                                                    <p>The Green Light Collision Center is a leading auto body repair shop in Los Angeles, which provides world-class auto body repair services. With years of professional experience, the Green Light Collision Center has found its deserved place in the market. We deal with both minor and major accidents, ensuring the car collision repair is completely executed and inspected. From repairing small dents and scratches to heavy collision repair, our technicians are "I-CAR" and "ASE" certified to handle all aspects of your repair needs. </p>
                                                    <p>Green Light Collision Center has invested in the proper tools needed to safely repair your vehicle back to manufacturer specifications. Also, the technicians working on your vehicle have undergone training and testing to ensure that they fully understand today's high-tech vehicles and they have the knowledge on how to properly repair them. Lastly, a certified collision repair facility has access to repair procedures to ensure that every part of the repair, down to something as simple as a weld, is done the way the manufacturer expects it to be done. </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <h2 className="title">Why Work With Us?</h2>
                                                        <p>Benefits of Green Light Collision Center:</p>
                                                    </div>
                                                    <div className="mt--30">
                                                        <ul className="list-style--1">
                                                            <li><FiCheck />Over 40 years of experience,</li>
                                                            <li><FiCheck />Certified Auto Body Repair Technicians,</li>
                                                            <li><FiCheck />State of Art Repair,</li>
                                                            <li><FiCheck />Contemproary Car Collision Repair Equipment,</li>
                                                            <li><FiCheck />Excellent Customer Service,</li>
                                                            <li><FiCheck />Budget-Friendly Car Repairs,</li>
                                                            <li><FiCheck />Free Estimates,</li>
                                                            <li><FiCheck />Rental Assitance,</li>
                                                            <li><FiCheck />Accindent Assistance,</li>
                                                            <li><FiCheck />24/7 Towing Service,</li>
                                                            <li><FiCheck />Wide range of services, including auto body shop, collision repair, paintless dent repair, auto paint and much more.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/about/benefits.jpg" alt="Benefits of Green Light Collision Center"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About