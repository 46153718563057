import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BrandTwo from "../elements/BrandTwo";
import Header from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--17',
        category: '',
        title: 'Green Light',
        description: 'At Green Light Collision Center, our goal is to get you back on the road as quickly as possible while performing the safest repair for you and your family.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-green-light-collision-center'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--18',
        category: '',
        title: 'Collision Center',
        description: 'Certified collision repair facilities have the distinguished honor of having met the highest standards in the collision repair industry.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-green-light-collision-center'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--19',
        category: '',
        title: 'Auto Repair Shop',
        description: 'Green Light Collision Center is a team of collision repair experts that are focused exclusively on bringing the highest quality, safest repair to our customers.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-green-light-collision-center'
    }
]

class Business extends Component{
    constructor (props) {
        super(props)
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this);
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        return(
            <Fragment> 
                <Helmet pageTitle="Auto Body Repair Shop Los Angeles" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--5 ptb--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                                    <h2 className="title">Our Service</h2>
                                    <p>We service all vehicle brands with expert knowledge from luxury vehicles to family-friendly brands. Green Light Collision Center makes sure auto repairs are done with the highest quality and on time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/benefits.jpg" alt="Benefits"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Why Work With Us?</h2>
                                        <p>Benefits of Green Light Collision Center:</p>
                                    </div>
                                    <div className="mt--30">
                                        <ul className="list-style--1">
                                            <li><FiCheck />Over 40 years of experience,</li>
                                            <li><FiCheck />Certified Auto Body Repair Technicians,</li>
                                            <li><FiCheck />State of Art Repair,</li>
                                            <li><FiCheck />Contemproary Car Collision Repair Equipment,</li>
                                            <li><FiCheck />Excellent Customer Service,</li>
                                            <li><FiCheck />Budget-Friendly Car Repairs,</li>
                                            <li><FiCheck />Free Estimates,</li>
                                            <li><FiCheck />Rental Assitance,</li>
                                            <li><FiCheck />Accindent Assistance,</li>
                                            <li><FiCheck />24/7 Towing Service,</li>
                                            <li><FiCheck />Wide range of services, including auto body shop, collision repair, paintless dent repair, auto paint and much more.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About Us</h2>
                                        <p className="description">The Green Light Collision Center is a leading auto body repair shop in Los Angeles, which provides world class auto body repair services. With years of professional experience, the Green Light Collision Center has found its deserved place in the market. We deal with both minor and major accidents, ensuring the car collision repair is completely executed and inspected. From repairing small dents and scratches to heavy collision repair, our technicians are "I-CAR" and "ASE" certified to handle all aspects of your repair needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/about-green.jpg" alt="About"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}



                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Business;